@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

#mainLayout {
  height: 100%;
  display: grid;
  grid-template-rows: 80px auto;
}
